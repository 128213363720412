export const collapsible = () => {
  const buttons = document.querySelectorAll('[data-collapsible]');

  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();

      const name = button.dataset.collapsible;
      const container = document.querySelectorAll(`[data-collapsible-container=${name}]`)[0];

      button.style.display = 'none';
      container.classList.add('collapsible-open');
    });
  });
};
