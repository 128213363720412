import { hideElement, showBlockElement, showFlexElement } from "./dom";

export const updateSectionHeaders = (missions) => {
  const emptyBox = document.querySelector('[data-search-empty]');
  const finishedHeader = document.querySelector("[data-header-type=finished]")
  const upcomingHeader = document.querySelector("[data-header-type=upcoming]")

  if (missions.some(({ mission }) => mission.isFinished === '1')) {
    showBlockElement(finishedHeader);
  } else {
    hideElement(finishedHeader);
  }

  if (missions.some(({ mission }) => mission.isFinished === '0')) {
    showBlockElement(upcomingHeader);
  } else {
    hideElement(upcomingHeader);
  }

  if (missions.length > 0) {
    hideElement(emptyBox);
  } else {
    showFlexElement(emptyBox);
  }
};

export const updateYearHeaders = (hide) => {
  if (hide) {
    document.querySelectorAll('.year_header').forEach((header) => {
      hideElement(header);
    });
  } else {
    document.querySelectorAll('.year_header').forEach((header) => {
      showFlexElement(header);
    });
  }
};
