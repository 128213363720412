const stringsLikeEqual = (a, b) => {
  if (a === null || b === null) {
    return false;
  }

  return a.toLowerCase().includes(b.toLowerCase())
};

export const hasQuery = (mission, query) => {
  return [
    stringsLikeEqual(mission.title, query),
    stringsLikeEqual(mission.rocket.rocket, query),
    mission.rocket.booster === query,
    stringsLikeEqual(mission.payload.names, query),
    mission.payload.orbit === query,
  ].some((result) => result);
};

export const filterMissions = (data, values) => {
  return data.filter(({ mission }) => {
    if (values.szukaj && !hasQuery(mission, values.szukaj)) {
      return false;
    }

    return true;
  })
};
