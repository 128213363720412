export const activateEditLinks = async () => {
  const links = document.querySelectorAll('[data-edit-link]');

  if (links.length === 0) {
    return;
  }

  const response = await fetch('/api/user');
  const data = await response.json();

  if (data.edit === false) {
    return;
  }

  links.forEach((link) => {
    link.classList.add('edit_link--active');
  });
};
