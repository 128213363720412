import { filterMissions } from "./filter";
import { hideElement, showGridElement } from "./dom";
import { updateSectionHeaders, updateYearHeaders } from "./headers";

export const refreshListFactory = (data, missionsList) => {
  let currentlyVisible = data.length;

  return (values) => {
    const filteredMissions = filterMissions(data, values);

    if (filteredMissions.length !== currentlyVisible) {
      missionsList.classList.add('archive__list--hidden');

      setTimeout(() => {
        data.forEach((item) => {
          if (filteredMissions.includes(item)) {
            showGridElement(item.element)
          } else {
            hideElement(item.element)
          }
        });

        updateSectionHeaders(filteredMissions);
        updateYearHeaders(data.length !== filteredMissions.length);
        missionsList.classList.remove('archive__list--hidden');
      }, 200)

      currentlyVisible = filteredMissions.length;

      return true;
    } else {
      return false;
    }
  };
}
