function reverseEmailObfuscation(element) {
    const email = element.dataset.email.split('').reverse().join('');

    element.href = `mailto:${email}`;
    element.innerText = email;
    element.classList.remove('text_placeholder');
}

function removeEmailsProtection() {
    document.querySelectorAll('[data-mail-protector]').forEach((element) => {
        reverseEmailObfuscation(element);
    })
}

export { removeEmailsProtection }
