import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

export const setupGallery = () => {
  const pswpElement = document.querySelector('.pswp');
  const windowInnerWidth = window.innerWidth;
  const windowInnerHeight = window.innerHeight;

  const items = [];
  let ps;

  document.querySelectorAll('[data-gallery]').forEach((image) => {
    items.push({
      src: image.dataset.gallery,
      title: image.dataset.galleryLabel,
      w: image.clientWidth,
      h: image.clientHeight,
      pid: new URL(image.dataset.gallery).pathname.split('/').splice(2).join('/'),
      image,
    });
  });

  document.querySelectorAll('img.gallery').forEach((image) => {
    items.push({
      src: image.src,
      title: image.alt,
      w: image.naturalWidth,
      h: image.naturalHeight,
      pid: new URL(image.src).pathname.split('/').splice(2).join('/'),
      image,
    });
  });

  const openModal = (index) => {
    const coreOptions = {
      index,
      showHideOpacity: true,
      bgOpacity: 0.9,
      loop: true,
      closeOnScroll: false,
      galleryUID: 'content',
      galleryPIDs: true,
      mainClass: 'lightbox',
      getThumbBoundsFn: (index) => {
        const thumbnail = items[index].image;
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const rect = thumbnail.getBoundingClientRect();

        return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
      },
      shareButtons: [
        {id:'facebook', label:'Udostępnij na Facebooku', url:'https://www.facebook.com/sharer/sharer.php?u={{url}}'},
        {id:'twitter', label:'Wyślij na Twittera', url:'https://twitter.com/intent/tweet?text={{text}}&url={{url}}'},
        {id:'download', label:'Pobierz grafikę', url:'{{raw_image_url}}', download: true}
      ]
    }

    ps = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, coreOptions);
    ps.init();
  };

  items.forEach((item, index) => {
    item.image.addEventListener('click', (event) => {
      event.preventDefault();
      openModal(index);
    });
  });

  const searchParams = new URLSearchParams(window.location.hash.substring(2))
  const gid = searchParams.get('gid');
  const pid = searchParams.get('pid');
  console.log(gid, pid);

  if (gid && pid) {
    const index = items.findIndex((item) => item.src.endsWith(pid));
    if (index !== undefined) {
      openModal(index);
    }
  }
};
