import './styles/global.scss';
import 'animate.css/animate.css';

/* website component */
import "./styles/components/website/overwrites.scss";
import "./styles/components/website/topbar.scss";
import "./styles/components/website/navigation.scss";
import "./styles/components/website/mobile-navigation.scss";
import "./styles/components/website/website_layout.scss";
import "./styles/components/website/pagination.scss";
import "./styles/components/website/more.scss";
import "./styles/components/website/archive.scss";
import "./styles/components/website/footer.scss";
import "./styles/components/website/tags.scss";
import "./styles/components/website/comments.scss";
import "./styles/components/website/icons.scss";
import "./styles/components/website/text_placeholder.scss";
import "./styles/components/website/information.scss";
import "./styles/components/website/interview.scss";
import "./styles/components/website/gdpr.scss";
import "./styles/components/website/message.scss";
import "./styles/components/website/read_more.scss";
import "./styles/components/website/separator.scss";
import "./styles/components/website/lightbox.scss";
import "./styles/components/website/canvasTabs.scss";
import "./styles/components/website/empty.scss";
import "./styles/components/website/form.scss";

/* page component */
import "./styles/components/page/page.scss";
import "./styles/components/page/title.scss";
import "./styles/components/page/label.scss";
import "./styles/components/website/interview";

/* frontpage */
import "./styles/components/frontpage/jumbotron.scss";
import "./styles/components/frontpage/next_mission.scss";

/* articles */
import "./styles/components/articles/box.scss";
import "./styles/components/articles/card.scss";
import "./styles/components/articles/thumbnail.scss";
import "./styles/components/articles/update.scss";

/* missions */
import "./styles/components/missions/row.scss";
import "./styles/components/missions/tools.scss";
import "./styles/components/missions/table.scss";
import "./styles/components/missions/data.scss";
import "./styles/components/missions/counter.scss";
import "./styles/components/missions/label.scss";
import "./styles/components/missions/crew.scss";
import "./styles/components/missions/year_header.scss";

/* live */
import "./styles/components/live/player.scss";
import "./styles/components/live/mission.scss";

/* widgets */
import "./styles/components/widgets/widget.scss";
import "./styles/components/widgets/trailer.scss";
import "./styles/components/widgets/facebook.scss";
import "./styles/components/widgets/twitter.scss";
import "./styles/components/widgets/popular.scss";
import "./styles/components/widgets/tags.scss";
import "./styles/components/widgets/sites.scss";

/* authors */
import "./styles/components/authors/box.scss";
import "./styles/components/authors/author.scss";
import "./styles/components/authors/links.scss";

/* search */
import "./styles/components/search/form.scss";
import "./styles/components/search/widget.scss";
