import Cookie from "js-cookie";

const GDPR_COOKIE_NAME = 'spacex_gdpr_consent';
const GDPR_COOKIE_EXPIRATION_DAYS = 90;

function enableUserTracking() {
  console.log('enable user tracking');
  if(window.enableGoogleAnalyticsTracking) {
    window.enableGoogleAnalyticsTracking();
  }
  if(window._paq) { // Motomo/Piwik
    window._paq.push(['rememberConsentGiven']);
  }
}

function isGdprConsent() {
  const value = Cookie.get(GDPR_COOKIE_NAME);
  return value === "true" ? true : !!value;
}

function setGdprConsent(value) {
  Cookie.set(GDPR_COOKIE_NAME, value, { expires: GDPR_COOKIE_EXPIRATION_DAYS });
}

function gdprConsentGivenHandler() {
  if(!isGdprConsent()) {
    setGdprConsent(true);
  }
}

export const gdpr = () => {
  const gdprWindow = document.querySelector('[data-gdpr]');

  if (!gdprWindow) {
    enableUserTracking();
    return;
  }

  if (isGdprConsent()) {
    enableUserTracking();
  } else {
    gdprWindow.classList.add('gdpr--enabled');

    gdprWindow.querySelector('[data-gdpr-close]').addEventListener('click', () => {
      gdprWindow.classList.remove('gdpr--enabled');
      setGdprConsent(true);
      enableUserTracking();
    });
  }
};
