import snowStorm from "./external/snowStorm";

const showStarsLayer = (show) => {
  const element = document.getElementById('shooting-stars');
  element.style.display = show ? 'block' : 'none';
};

const playShootingStarsAnimation = (element, link) => {
  if (element.starsShoot) {
    window.location.href = link;
    return;
  }

  element.starsShoot = true;

  showStarsLayer(true);
  snowStorm.start();

  setTimeout(() => {
    window.location.href = link;

    setTimeout(() => {
      snowStorm.stop();
      showStarsLayer(false);
    }, 100);
  }, 3000);
};

const addEffect = (element) => {
  element.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
    });
  });

  element.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    playShootingStarsAnimation(element, element.dataset.authorLink);
  });
}

export const shootingStars = () => {
  const elements = document.querySelectorAll('[data-author]');

  elements.forEach((element) => {
    if (element.dataset.author === 'joanna-blaszczyk') {
      addEffect(element);
    } else {
      element.addEventListener('click', () => {
        window.location.href = element.dataset.authorLink;
      });
    }
  });
};
