export const boxlink = () => {
  const boxes = document.querySelectorAll('[data-link]');

  boxes.forEach((box) => {
    box.addEventListener('click', (event) => {
      const selection = window.getSelection();

      if (selection.type === 'Range' && box.contains(selection.anchorNode)) {
        return;
      }
      if (event.target.tagName === 'A') {
        return;
      }

      window.location.href = box.dataset.link;
    });
  });
};
