export const showBlockElement = (element) => {
  element.style.display = 'block';
};

export const showGridElement = (element) => {
  element.style.display = 'grid';
};

export const showFlexElement = (element) => {
  element.style.display = 'flex';
};

export const hideElement = (element) => {
  element.style.display = 'none';
};
