import { createForm } from 'final-form';
import { showBlockElement } from "./dom";

const registeredFields = {};

const registerField = (form, input) => {
  const clearButton = document.querySelector('[data-search-clear]');

  form.registerField(input.name, (fieldState) => {
    const { value, change } = fieldState

    if (!registeredFields[name]) {
      input.addEventListener('input', event => {
        change(event.target.value);
      })

      input.addEventListener('keyup', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          event.target.blur()
        }
      });

      registeredFields[name] = true
    }

    input.value = value === undefined ? '' : value
  }, {
    value: true,
  });
}

export const registerForm = (initialValues, formName, onSubmit) => {
  const formElement = document.forms[formName];
  const inputsElements = Array.from(formElement.elements).filter((input) => !!input.name);

  const form = createForm({
    onSubmit,
    initialValues,
  });

  formElement.addEventListener('submit', (event) => {
    event.preventDefault();
  });

  inputsElements.forEach((input) => {
    registerField(form, input);

    input.addEventListener('input', () => {
      form.submit();
    });
  })

  return form;
};
