function getWrapper() {
    const wrapper = document.createElement('figure');
    wrapper.classList.add('thumb');

    return wrapper;
}

function getCaption(text) {
    const caption = document.createElement('figcaption');
    caption.innerText = text;

    return caption;
}

function wrapImageIntoFigure(image) {
    const wrapper = getWrapper();
    const caption = getCaption(image.alt);

    wrapper.style.cssText = image.style.cssText;
    image.style.cssText = '';

    image.parentNode.insertBefore(wrapper, image);

    wrapper.appendChild(image);
    wrapper.appendChild(caption)


    if (image.classList.contains('centered')) {
        wrapper.classList.add('centered');
    }

    wrapper.style.height = 'auto';
}

function captionImages() {
    document.querySelectorAll('img.captioned').forEach((element) => {
        wrapImageIntoFigure(element);
    });
}

export { captionImages };
