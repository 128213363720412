const activeMenuButtonClass = 'mobile-navigation--active';
const openMobileMenuClass = 'navigation--mobile-open';
const staleBodyClass = 'body-stale';
import cssVars from './../../styles/variables.scss';

let mobileMenuOpen = false;

export function mobileNavigation() {
  const button = document.getElementById('mobile_navigation');
  const menu = document.getElementById('navigation');
  const items = menu.querySelectorAll('a');

  const handleClick = () => {
    if (window.matchMedia(`(min-width: ${cssVars.breakpointSmall})`).matches) {
      return;
    }

    if (mobileMenuOpen) {
      button.classList.remove(activeMenuButtonClass);
      menu.classList.remove(openMobileMenuClass);
      document.body.classList.remove(staleBodyClass);
    } else {
      button.classList.add(activeMenuButtonClass);
      menu.classList.add(openMobileMenuClass);
      document.body.classList.add(staleBodyClass);
    }

    mobileMenuOpen = !mobileMenuOpen;
  };

  button.addEventListener('click', handleClick);
  items.forEach((link) => link.addEventListener('click', handleClick));
}
