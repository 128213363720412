import { DateTime } from 'luxon';


const formatDiff = (diff) => {
    if (diff.days >= 1) {
        return diff.toFormat("d'd' hh'h' mm'm' ss's'");
    }
    if (diff.hours >= 1) {
        return diff.toFormat("hh'h' mm'm' ss's'");
    }
    if (diff.minutes >= 1) {
        return diff.toFormat("mm'm' ss's'");
    }
    return diff.toFormat("ss's'");
};

function createTimeCounter(counter) {
    const counterDisplay = counter.children[0];
    const rawDate = counter.dataset.counter;
    const inProgressText = counter.dataset.inProgress;

    const date = DateTime
      .fromSQL(rawDate,{ zone: 'Europe/Warsaw' })
      .setZone('local');

    function tick() {
        const diff = date.diff(DateTime.local()).shiftTo('days', 'hours', 'minutes', 'seconds');
        const formattedDiff = formatDiff(diff);

        if(diff.seconds > 0) {
            counterDisplay.innerText = formattedDiff;
        } else {
            counterDisplay.innerText = inProgressText;
            counterDisplay.classList.add('time_counter__text--pulse');
            clearInterval(intervalId);
        }
    }

    const intervalId = setInterval(tick, 1000);
    tick();

    counterDisplay.style.opacity = 1;
}

export function buildTimeCounters() {
    const counters = document.querySelectorAll('[data-counter]');

    counters.forEach((element) => {
        createTimeCounter(element);
    });
}
