import './styles';
import { buildTimeCounters } from './src/front/timeCounter';
import { captionImages } from './src/front/imageCaptioner';
import { mobileNavigation } from './src/front/mobileNavigation';
import { removeEmailsProtection } from './src/front/emailProtector';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import "./src/front/gdpr.js";
import "./src/front/shootingStars.js";
import { boxlink } from "./src/front/boxlink";
import { setupGallery } from "./src/front/setupGallery";
import imagesLoaded from 'imagesloaded';
import { collapsible } from "./src/front/collapsible";
import { missionsFilters } from "./src/front/missionFilters/missionsFilters";
import { shootingStars } from "./src/front/shootingStars";
import { gdpr } from "./src/front/gdpr.js";
import { smoothScrollAnchor } from "smooth-scroll-anchor";
import { activateEditLinks } from "./src/front/activateEditLinks";
import isMobile from 'is-mobile';

document.body.classList.remove('preload');

activateEditLinks();

mobileNavigation();
buildTimeCounters();
removeEmailsProtection();
captionImages();

imagesLoaded('.page', () => {
    setupGallery();
});

collapsible();
missionsFilters();

boxlink();
shootingStars();

tippy('[data-tooltip]', {
    arrow: true,
    animation: 'fade',
    placement: 'bottom',
    offset: [0, 10],
    content: (reference) => reference.dataset.tooltip,
});

gdpr();

smoothScrollAnchor({
    behaviour: "smooth",
    block: "start",
    offset: isMobile() ? 80 : 10,
});
