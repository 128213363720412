import { hideElement, showBlockElement } from "./dom";
import { registerForm } from "./form";
import { history } from "../../history";
import qs from 'query-string';
import debounce from 'debounce';
import { refreshListFactory } from "./render";

const defaultValues = {
  szukaj: '',
};

const getData = (missionsElements) => {
  return Array.from(missionsElements).map((element) => {
    return {
      element,
      mission: JSON.parse(element.dataset.serialized),
    }
  });
};

const getCleanQueryString = (values) => {
  const validKeys = Object.keys(values).filter((key) => !!values[key]);
  const entries = validKeys.map((key) => [key, values[key]]);

  return qs.stringify(Object.fromEntries(entries));
};

export const missionsFilters = () => {
  const formElement = document.querySelector('[data-mission-filters]');

  if (!formElement) {
    return;
  }

  const missionsElements = document.querySelectorAll('[data-serialized]');
  const missionsList = document.querySelector('[data-missions-list]');
  const queryClearButton = formElement.querySelector('[data-search-clear]');

  const data = getData(missionsElements);
  const refreshList = refreshListFactory(data, missionsList);

  const onFiltersChange = debounce((values) => {
    if (refreshList(values)) {
      history.push(`/misje?${getCleanQueryString(values)}`);
    }
  }, 350);

  const initialValues = {
    ...defaultValues,
    ...qs.parse(location.search),
  };

  const form = registerForm(initialValues, 'mission_search', onFiltersChange);

  refreshList(initialValues);

  queryClearButton.addEventListener('click', () => {
    form.change('szukaj', '');
    onFiltersChange(form.getState().values);
  });

  form.subscribe((state) => {
    state.values.szukaj ? showBlockElement(queryClearButton) : hideElement(queryClearButton);
  }, {
    values: true,
  })

  history.listen(({ action, location}) => {
    if (action === 'POP') {
      const values = { ...defaultValues, ...qs.parse(location.search) };

      Object.keys(form.getState().values).forEach((key) => {
        form.change(key, values[key]);
      });

      refreshList(values);
    }
  });
};
